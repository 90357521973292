import React from 'react';

const LandingPageNavigation = () => {
  return (
    <div>
      <h1
        id='madeline'>
          madeline
      </h1>
    </div>
  );
};

export default LandingPageNavigation;
